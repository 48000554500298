<template>
  <div>
    <Banna :Banna="Banna" />
    <div class="home-view">
      <div class="view-content">
        <div>
          <activityList />
        </div>
      </div>
      <div class="view-advertising">
        <Advertising />
        <Advertising2 />
      </div>
    </div>
  </div>
</template>
<script>
import activityList from './components/activityList';
export default {
  components: {
    activityList,
  },
  data() {
    return {
      Banna: '',
    };
  },
  mounted() {
    this.getBanna();
  },
  methods: {
    getBanna() {
      return this.$request(Apis.home.DATUS, { data: { id: '21' } })
        .then(data => {
          this.Banna = data.data.data;
          console.log(this.Banna);
        })
        .finally(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
.home-view {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.view-content {
  width: 65%;
}
.view-advertising {
  width: 25%;
}
</style>
