<template>
  <div>
    <div class="home-list" v-for="item in isLists" :key="item.id">
      <div class="list-theme">{{item.title}}</div>
      <div class="list-details">
        <div class="details-author">发布者：{{item.author}}</div>
        <div class="details-date">发布时间：{{ $moment(+item.addtime).format('YYYY-MM-DD') }}</div>
      </div>
      <div class="list-content">
        <div class="adapt">
          <div
            class="content-img"
            :style="{ backgroundImage: 'url(' + item.thumb + ')',backgroundSize:'cover' }"
          ></div>
          <div class="position"></div>
        </div>
        <div class="content-theme">
          <div class="content">{{item.description}}</div>
          <div class="more" @click="btnMore(item.id)">查看全部</div>
        </div>
      </div>
    </div>

    <div class="increase" @click="increase">{{!isIncrease?'查看更多':'收起'}}</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      allLists: [],
      lists: [],
      isLists: [],
      isIncrease: false,
    };
  },
  mounted() {
    this.honeList();
  },
  methods: {
    honeList() {
      return this.$request(Apis.marketDynamic.LISTS).then(data => {
        this.allLists = data.data.data;

        this.allLists.forEach((e, index) => {
          if (index < 3) {
            this.lists.push(e);
            this.isLists.push(e);
          }
        });
      });
    },
    increase() {
      this.isIncrease = !this.isIncrease;
      if (this.isIncrease) {
        this.isLists = this.allLists;
      } else {
        this.isLists = this.lists;
      }
    },
    btnMore(id) {
      this.$router.push('/details/' + id);
    },
  },
};
</script>
<style lang="less" scoped>
.home-list {
  margin-bottom: 20px;
  padding: 0 10px;
  .list-theme {
    font-size: 20px;
    font-weight: 700;
    color: #ffcc00;
  }
  .list-details {
    display: flex;
    color: #fff;
    margin: 5px 0;
    font-weight: 500;
    .details-author {
      margin-right: 20px;
    }
  }
  .list-content {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .adapt {
      width: 45%;
      position: relative;
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        //   padding-bottom: 100%;
        background: #eee;
      }
      .position{
        padding-bottom: 80%;
      }
    }

    .content-theme {
      width: 50%;
      height: 200px;

      .content {
        height: 75%;
        color: #fff;
        font-size: 14px;
        letter-spacing: 3px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
        overflow: hidden;
        line-height: 1.6;
      }
      .more {
        font-size: 12px;
        color: #fff;
        text-align: center;
        cursor: pointer;
        width: 25%;
        margin: auto;
        height: 30px;
        background: #ffcc00;
        line-height: 30px;
        border-radius: 15px;
        margin-top: 20px;
      }
    }
  }
}
.increase {
  width: 20%;
  height: 35px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin: auto;
  background: #ffcc00;
  line-height: 30px;
  border-radius: 15px;
}
</style>
