var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Banna", { attrs: { Banna: _vm.Banna } }),
      _c("div", { staticClass: "home-view" }, [
        _c("div", { staticClass: "view-content" }, [
          _c("div", [_c("activityList")], 1)
        ]),
        _c(
          "div",
          { staticClass: "view-advertising" },
          [_c("Advertising"), _c("Advertising2")],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }